import { useSystemContext } from '../Context/SystemContext';
import Grid from './Grid';
import { Input } from './Input';
const yup = require('../interface/yup-br');

export const GridAnexos = ({ url, perms, canShow, CanEdit, canInsert, canDelete, ...rest }) => {
    const System = useSystemContext();
    // permissoes
    const _canShow = false;

    const _canInsert = perms ? System.methods.getUserRule(`alterar${perms}`) : typeof canInsert === 'boolean' ? canInsert : true;

    const _canDelete = perms ? System.methods.getUserRule(`deletar${perms}`) : typeof canDelete === 'boolean' ? canDelete : true;

    return (
        <Grid
            url={url}
            {...rest}
            disableModal={true}
            GridHeaders={[
                {
                    title: 'Título',
                    field: 'titulo',
                    sort: true,
                    className: 'w-1/2 text-left pl-1',
                },
                {
                    title: 'Arquivo',
                    field: 'arquivo',
                    sort: true,
                    className: 'w-1/2 pl-1 text-left border-l border-gray-300',
                },
            ]}
            LineDataPrint={(item) => {
                return [item?.titulo, `#link=${item.arquivo}|view=${item.arquivo}`];
            }}
            validationSchema={() => {
                return yup.object().shape({
                    titulo: yup.string().required(),
                    arquivo: yup.string(),
                });
            }}
            onRenderForm={(GridModes, setFieldValue, values) => {
                return (
                    <>
                        <Input name="titulo" label="Título" className="w-92" />
                        <div className={`mt-1 w-full pl-2 pr-2`}>
                            <label htmlFor="file_input" className="block">
                                Arquivo
                            </label>
                            <input
                                id="file_input"
                                type="file"
                                className={`block w-full rounded-md border px-1 py-2 `}
                                onChange={(e) => {
                                    setFieldValue('arquivo', e.target.files[0]);
                                }}
                            />
                        </div>
                    </>
                );
            }}
            canEdit={false}
            canInsert={_canInsert}
            canDelete={_canDelete}
            canShow={_canShow}
            showPDFExport={false}
            showCSVExport={false}
            onPrepareSaveRecord={(values) => {
                let newForm = new FormData();
                for (let key in values) {
                    newForm.append(key, values[key]);
                }
                return newForm;
            }}
        />
    );
};
